import React from "react";
import * as Styled from "../stylesStepTab";
import AccordionComponent from "../../components/AccordionComponent";
import InputTextArea from "@components/Sales/Inputs/InputTextArea";
import { useFormik } from "formik";
import oportunityService from "@services/Oportunity/OportunityService";
import { OportunityContext } from "@context/oportunityContex";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";
import enterprisesService from "@services/Enterprises/enterprisesService";
import { optionCapaign, optionEnterprise } from "../../components/options";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import salesService from "@services/Sales/SalesService";
import { IValidationDetailOportuntySchema } from "src/@types/Oportunity";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { IDetailComponent } from "src/@types/Sales";
import { documentMask } from "@helpers/format";
import oportunityServiceInstance from "@services/Oportunity/OportunityService";
import { UsuallyContext } from "@context/usuallyContex";

const DetailComponent = ({
  indexFirstTab,
  edit,
  setEdit,
  setCurrentFase,
  updateData,
  setDataOportunidade,
  loadingGetOportunity,
  setloadingGetOportunity,
  setProbabilidade,
  cancelButtonAdress,
  setCancelButtonAdress,
}: IDetailComponent) => {
  const displayMessageError = useMessageErrorToast();
  const user: any = localStorage.getItem("@user");
  const regionalUser: any = localStorage.getItem("@regional");
  const tipoDeUsuario: any = localStorage.getItem("@tipoDeUsuario");
  const idUser: any = localStorage.getItem("@idCorretor");
  const {
    setNomeEmpreendimentoSales,
    setIdEmpreendimentoSales,
    setImobiliariaNome,
    setImobiliariaId,
    accessCreateOp,
    accountIdSalesforce,
    setAccountIdSalesforce,
    setAccessCreateOp,
    setVendaFutura,
    setDataDetailOportunity,
    setNomeConta,
    nomeConta,
    setIsUpdateAdress,
    setIsUpdateAdressIncome,
    setRegionalOp,
  } = React.useContext(OportunityContext);
  const { signOut } = React.useContext(LoginContext);
  const { hashDetailOportunity } = React.useContext(UsuallyContext);
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [nacionality, setNacionality] = React.useState("");
  const [ageRange, setAgeRange] = React.useState("");
  const [sex, setSex] = React.useState("");
  const [profession, setProfession] = React.useState("");
  const [civilState, setCivilState] = React.useState("");
  const [marriageRegime, setMarriageRegime] = React.useState("");
  const [typeSale, setTypeSale] = React.useState("");

  const [mainCampaign, setMainCampaign] = React.useState("");
  const [selectMainCampaign, setSelectMainCampaign] = React.useState("");
  const [fallReason, setFallReason] = React.useState("");

  const [mainEnterprises, setMainEnterprises] = React.useState("");
  const [selectMainEnterprises, setSelectMainEnterprises] = React.useState("");

  const [loadingUpdate, setLoadingUpdate] = React.useState(false);

  const [data, setData] = React.useState<any>([]);
  const [dataFormik, setDataFormik] =
    React.useState<IValidationDetailOportuntySchema>();
  const [require, setRequire] = React.useState(false);
  const [loadingEnterprises, setLoadingEnterprises] = React.useState(false);
  const [dataEnterprises, setDataEnterprises] = React.useState([]);
  const [idEnterprises, setIdEnterprises] = React.useState("");
  const [loadingCampaign, setLoadingCampaign] = React.useState(false);
  const [dataCampaign, setDataCampaign] = React.useState([]);
  const [idCampaign, setIdCampaign] = React.useState("");
  const [observations, setObservations] = React.useState("");
  const [originAccount, setOriginAccount] = React.useState("");
  const [commercialRegional, setCommercialRegional] = React.useState("");
  const [existCommercialRegional, setExistCommercialRegional] =
    React.useState(false);
  const [broker, setBroker] = React.useState("");
  const [selectBroker, setSelectBroker] = React.useState("");
  const [idBroker, setIdBroker] = React.useState("");
  const [dataBroker, setDataBroker] = React.useState<any>([]);
  const [loadingBroker, setLoadingBroker] = React.useState(false);
  const [regional, setRegional] = React.useState("");

  const renderedOptions = optionEnterprise(
    dataEnterprises,
    selectMainEnterprises,
    setSelectMainEnterprises,
    setMainEnterprises,
    setIdEnterprises
  );

  const handleGetEnterprises = async () => {
    setLoadingEnterprises(true);

    try {
      const response = await enterprisesService.getEnterprises({
        regional: regionalUser,
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingEnterprises(false);
        setDataEnterprises([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingEnterprises(false);
        setIsUnauthorized(true);
        setDataEnterprises([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataEnterprises(dataArray);
      setLoadingEnterprises(false);
    } catch (error) {
      setLoadingEnterprises(false);
      console.log("errorHandleGetEnterprises", error);
    }
  };

  const renderedOptionsCampaign = optionCapaign(
    dataCampaign,
    selectMainCampaign,
    setSelectMainCampaign,
    setMainCampaign,
    setIdCampaign
  );

  const handleGetCampaign = async () => {
    setLoadingCampaign(true);

    try {
      const response = await salesService.getCampaign();

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingCampaign(false);
        setDataCampaign([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingCampaign(false);
        setIsUnauthorized(true);
        setDataCampaign([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataCampaign(dataArray);
      setLoadingCampaign(false);
    } catch (error) {
      setLoadingCampaign(false);
      console.log("errorHandleGetCampaign", error);
    }
  };

  const handleGetBroker = async () => {
    setLoadingBroker(true);

    try {
      const response = await oportunityServiceInstance.getUserByEquip();

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingBroker(false);
        setDataBroker([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingBroker(false);
        setIsUnauthorized(true);
        setDataBroker([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataBroker(dataArray);
      setLoadingBroker(false);
    } catch (error) {
      setLoadingBroker(false);
      console.log("errorHandleGetBroker", error);
    }
  };

  const formatMoney = (valor: any) => {
    const newValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(valor);
    return newValue;
  };

  const valueNumberFormat = (valor: any) => {
    const newValor = valor
      ?.toString()
      .replace("R$", "")
      .replace(".", "")
      .replace(",", ".");
    const valorNumerico = parseFloat(newValor);
    return valorNumerico;
  };

  const initialValues = {
    idOportunity: dataFormik?.oportunidadeId || "",
    accountOrigin: dataFormik?.origemConta || "",
    nameAccount: dataFormik?.nomeDaConta || "",
    nameOportunity: dataFormik?.nome || "",
    realEstate: dataFormik?.imobiliaria || "",
    contactConsultant: dataFormik?.nomeCorretor || "",
    commercialRegion: dataFormik?.regionalComercial || "",

    enterprise: dataFormik?.nomeEmpreendimento || "",
    product: dataFormik?.produto || "",

    businessUnit: dataFormik?.unidadeDeNegocio || "",
    statusContract: dataFormik?.statusContrato || "",
    value: dataFormik?.valor || "",
    valueContract: dataFormik?.valorContrato || "",
    realValueSale: dataFormik?.valorRealVenda || "",
    fase: dataFormik?.fase || "",

    dateClosing: dataFormik?.dataFechamento || "",
    dateOportunity: dataFormik?.dataOportunidade || "",
    dateSale: dataFormik?.dataVenda || "",

    probability: dataFormik?.probabilidade || "",

    Fid: dataFormik?.fid || "",
    statusSafi: dataFormik?.statusSAFI || "",
    stageSafi: dataFormik?.etapaSAFI || "",
    signatureDate: dataFormik?.dataAssinatura || "",
    sicaq: dataFormik?.sicaq || "",
    futureSale: dataFormik?.vendaFutura || "",
    justification: dataFormik?.justificativaSAFI || "",
    awardFolder: formatMoney(dataFormik?.pastaPremiada) || "",
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoadingUpdate(true);
      setIsUpdateAdressIncome(false);

      try {
        const selectedEnterprise: any = dataEnterprises.find(
          (e: any) => e.nomeEmpreendimento === selectMainEnterprises
        );

        let empreendimentoDeInteressePrincipal = idEnterprises;
        if (selectedEnterprise) {
          empreendimentoDeInteressePrincipal =
            selectedEnterprise.idEmpreendimentoSales;
        }

        const bodyNotRegionl = {
          opportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
          OportunidadeId: accessCreateOp.oportunidadeId,
          accountIdSalesforce: accountIdSalesforce,
          origemDaConta: getValidStringOrNull(originAccount),
          nacionalidade: getValidStringOrNull(nacionality),
          faixaEtaria: getValidStringOrNull(ageRange),
          sexo: getValidStringOrNull(sex),
          profissao: getValidStringOrNull(profession),
          estadoCivil: getValidStringOrNull(civilState),
          regimeCasamento: getValidStringOrNull(marriageRegime),
          empreendimentoDeInteressePrincipal: getValidStringOrNull(
            empreendimentoDeInteressePrincipal
          ),
          campanha: getValidStringOrNull(idCampaign),
          tipoVenda: getValidStringOrNull(typeSale),
          observacao: observations,
          contatoCorretor: tipoDeUsuario === "Gerente" ? idBroker : idUser,
          pastaPremiada: valueNumberFormat(values.awardFolder),
        };

        const body = {
          ...bodyNotRegionl,
          regionalComercial: getValidStringOrNull(
            commercialRegional?.toUpperCase()
          ),
        };

        const response = await oportunityService.updateOportunity(
          regional === "SP" ? body : bodyNotRegionl
        );

        if (response.success === false) {
          setLoadingUpdate(false);
          displayMessageError({ message: response.err });
          setAccessCreateOp({ ...accessCreateOp });
          return;
        }

        setEdit(false);
        setAccessCreateOp({ ...accessCreateOp });
        setLoadingUpdate(false);
      } catch (error) {
        setLoadingUpdate(false);
        console.log("ErrorHandleGetOportunityById", error);
      }
    },
  });

  const isCnpjComplete =
    documentMask(formik.getFieldProps("cpf").value)?.length === 18;

  const disabledCpfCnpj =
    documentMask(formik.getFieldProps("cpf").value)?.length > 0 &&
    (documentMask(formik.getFieldProps("cpf").value)?.length < 14 ||
      (documentMask(formik.getFieldProps("cpf").value)?.length > 14 &&
        !isCnpjComplete));

  const handleCancelButton = () => {
    setEdit(false);
    setCancelButtonAdress(false);
    formik.resetForm(initialValues);
    setOriginAccount(data?.origemConta);
    setNacionality(data?.nacionalidade);
    setAgeRange(data?.faixaEtaria);
    setSex(data?.sexo);
    setProfession(data?.profissao);
    setCivilState(data?.estadoCivil);
    setMarriageRegime(data?.regimeCasamento);
    setTypeSale(data?.tipoVenda);
    setFallReason(data?.motivoQueda);
  };

  React.useEffect(() => {
    if (tipoDeUsuario === "Gerente") {
      const isRequirement =
        !broker || (regional === "SP" && !commercialRegional);
      setRequire(isRequirement);
    } else {
      const isRequirement = regional === "SP" && !commercialRegional;
      //  ||requireAdress;

      setRequire(isRequirement);
    }
  }, [broker, commercialRegional, regional, tipoDeUsuario]);

  React.useEffect(() => {
    const handleGetOportunityById = async () => {
      setloadingGetOportunity(true);
      try {
        const response = await oportunityService.getOportunityById({
          OportunidadeId: accessCreateOp.oportunidadeId,
        });

        if (response.status === 401) {
          setloadingGetOportunity(false);
          return;
        }

        setRegionalOp(response?.regionalOp);
        setRegional(response?.regional);
        setIdBroker(response?.contatoCorretor);
        setBroker(response?.nomeCorretor);
        setSelectBroker(response?.nomeCorretor);
        setExistCommercialRegional(response?.regionalComercial !== null);

        const handleSetRegionalComercial = () => {
          if (response?.regionalComercial) {
            return response?.regionalComercial;
          } else {
            if (response?.regional === "GO") {
              return "DF";
            } else if (
              response?.regional !== "SP" ||
              response?.regional === "GO"
            ) {
              return response?.regional;
            } else {
              return response?.regionalComercial;
            }
          }
        };

        setCommercialRegional(handleSetRegionalComercial());
        setAccessCreateOp((prevState: any) => {
          return {
            ...prevState,
            nomeDaConta: response?.nomeDaConta,
            oportunityIdSalesforce: response?.opportunityIdSalesforce,
          };
        });
        setOriginAccount(response.origemConta);
        setNacionality(response.nacionalidade);
        setCurrentFase(response.fase);
        setAgeRange(response.faixaEtaria);
        setSex(response.sexo);
        setProfession(
          response?.profissao === "" || response?.profissao === null
            ? ""
            : response?.profissao
        );
        setCivilState(response.estadoCivil);
        setObservations(
          response.observacao === null || response.observacao === null
            ? ""
            : response?.observacao
        );
        setMarriageRegime(response.regimeCasamento);
        setTypeSale(response.tipoVenda);
        setFallReason(response.motivoQueda);
        setSelectMainEnterprises(
          response.nomeEmpreendimentoDeInteresse
            ? response.nomeEmpreendimentoDeInteresse
            : ""
        );
        setMainEnterprises(
          response.nomeEmpreendimentoDeInteresse
            ? response.nomeEmpreendimentoDeInteresse
            : ""
        );
        setIdEnterprises(
          response.empreendimentoDeInteressePrincipal
            ? response.empreendimentoDeInteressePrincipal
            : ""
        );
        setDataOportunidade(response.dataOportunidade);
        setProbabilidade(response.probabilidade);

        setNomeEmpreendimentoSales(response.nomeEmpreendimento);
        setIdEmpreendimentoSales(response.idEmpreendimentoSales);
        setImobiliariaNome(response.imobiliaria);
        setImobiliariaId(response.imobiliariaId);
        setAccountIdSalesforce(response.accountIdSalesforce);
        setNomeConta(response.nomeDaConta);
        setVendaFutura(response.vendaFutura);
        setDataDetailOportunity(response);
        setData(response);
        setDataFormik(response);
        setloadingGetOportunity(false);
        setSelectMainCampaign(
          response.nomeCampanha ? response.nomeCampanha : ""
        );
        setMainCampaign(response.nomeCampanha ? response.nomeCampanha : "");
        setIdCampaign(response.campanha ? response.campanha : "");
      } catch (error) {
        setloadingGetOportunity(false);
        console.log("ErrorHandleGetOportunityById", error);
      }
    };

    if (indexFirstTab === 0 && hashDetailOportunity) {
      handleGetOportunityById();
    }
  }, [
    hashDetailOportunity,
    indexFirstTab,
    setAccountIdSalesforce,
    setDataDetailOportunity,
    setNomeConta,
    setVendaFutura,
    setCurrentFase,
    updateData,
    setDataOportunidade,
    setloadingGetOportunity,
    setProbabilidade,
    setNomeEmpreendimentoSales,
    setIdEmpreendimentoSales,
    setImobiliariaNome,
    setImobiliariaId,
    accessCreateOp.oportunidadeId,
    setAccessCreateOp,
    setRegionalOp,
  ]);

  React.useEffect(() => {
    if (data?.empreendimentoDeInteressePrincipal === "" || null) {
      setMainEnterprises("");
      setSelectMainEnterprises("");
    }
    if (data?.nomeEmpreendimentoDeInteresse === "" || null) {
      setIdEnterprises("");
    }
  }, [
    data?.empreendimentoDeInteressePrincipal,
    data?.nomeEmpreendimentoDeInteresse,
  ]);

  React.useEffect(() => {
    if (data?.nomeCampanha === "" || null) {
      setSelectMainCampaign("");
      setMainCampaign("");
    }
    if (data?.campanha === "" || null) {
      setIdCampaign("");
    }
  }, [data?.nomeCampanha, data?.campanha]);

  if (loadingGetOportunity) {
    return (
      <Styled.LoadingContainer>
        <Spinner />
      </Styled.LoadingContainer>
    );
  }

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />

      <Styled.ContainerDetail>
        <Styled.ContainerAccordion>
          <AccordionComponent
            formik={formik}
            isEditConsultor={broker
              ?.toLocaleLowerCase()
              ?.includes(user?.toLocaleLowerCase())}
            regional={regional}
            broker={broker}
            setBroker={setBroker}
            selectBroker={selectBroker}
            setSelectBroker={setSelectBroker}
            loadingBroker={loadingBroker}
            dataBroker={dataBroker}
            setIdBroker={setIdBroker}
            getBroker={handleGetBroker}
            existCommercialRegional={existCommercialRegional}
            commercialRegional={commercialRegional}
            setCommercialRegional={setCommercialRegional}
            edit={edit}
            typeSale={typeSale}
            setTypeSale={setTypeSale}
            mainCampaign={mainCampaign}
            setMainCampaign={setMainCampaign}
            selectMainCampaign={selectMainCampaign}
            setSelectMainCampaign={setSelectMainCampaign}
            loadingCampaign={loadingCampaign}
            fallReason={fallReason}
            setFallReason={setFallReason}
            mainEnterprises={mainEnterprises}
            setMainEnterprises={setMainEnterprises}
            selectMainEnterprises={selectMainEnterprises}
            setSelectMainEnterprises={setSelectMainEnterprises}
            loadingMainEnterprise={loadingEnterprises}
            handleGetEnterprises={handleGetEnterprises}
            getEnterprises={renderedOptions}
            handleGetCampaign={handleGetCampaign}
            getCampaign={renderedOptionsCampaign}
            setMarrigeRegime={setMarriageRegime}
          />
        </Styled.ContainerAccordion>

        <Styled.ContainerInputObservation>
          <InputTextArea
            readOnly={!edit}
            placeholder="Observações sobre a conta em questão..."
            label="Observações:"
            name="observations"
            value={observations}
            onChange={(e) => setObservations(e.target.value)}
          />
        </Styled.ContainerInputObservation>

        <Styled.DividerBoxInput />

        <Styled.ContainerFooterBox>
          <Styled.ContainerDuoMandatory>
            <span>(*) Campo de preenchimento obrigatório</span>
            <span>
              (<AiOutlineInfoCircle />) O campo apresenta formato ou dado
              inválido
            </span>
          </Styled.ContainerDuoMandatory>
          <Styled.ContainerButtonFooterEdit>
            <Button
              error
              title="CANCELAR"
              onClick={() => {
                handleCancelButton();
                setCancelButtonAdress(true);
              }}
              disabled={!edit}
            />

            <Button
              title={loadingUpdate ? <Spinner /> : "SALVAR"}
              onClick={() => {
                formik.handleSubmit();
              }}
              disabled={
                loadingUpdate || !edit || (edit && require) || disabledCpfCnpj
              }
            />
          </Styled.ContainerButtonFooterEdit>
        </Styled.ContainerFooterBox>
      </Styled.ContainerDetail>
    </>
  );
};

export default DetailComponent;

const getValidStringOrNull: (v?: string) => string | null = (v) => {
  if (v === undefined) return null;
  if (v === "") return null;
  return v;
};

const getValidDateOrNull: (v?: string) => string | null = (v) => {
  if (v === undefined) return null;
  if (v === "") return null;
  if (v.length !== 10) return null;

  const fragments = v.split("/");
  return `${fragments[2]}-${fragments[1]}-${fragments[0]}T12:00:00.000Z`;
};

import React from "react";
import * as Styled from "../stylesView&Create";
import Alert from "@components/common/Alert";
import { LoginContext } from "@context/loginContext";
import Spinner from "@components/common/Spinner";
import oportunityService from "@services/Oportunity/OportunityService";
import { OportunityContext } from "@context/oportunityContex";
import { documentMask } from "@helpers/format";
import { useMessageErrorToast } from "@hook/useMessageErrorToast";
import { useOnlyMessageToast } from "@hook/useMessageToast";
import { IViewRalationshipComponent } from "src/@types/Sales";
import { Box, Flex, Text } from "@chakra-ui/react";
import { theme } from "src/styles/theme";
import { BiUser } from "react-icons/bi";
import { BsArrowLeft } from "react-icons/bs";
import AccordionBuyersComponent from "../../components/AccordionBuyersComponent";
import { useFormik } from "formik";
import { IValidationBuyersAccordionSchema } from "src/@types/Oportunity";

const ViewRalationshipComponent = ({
  onBack,
  title,
  idRelacionamentoComprador,
  account,
  isEdit,
  setIsEdit,
  setSuccessEditRelationship,
  compradorPrincipal,
  accountIdSalesforce,
  setAccountIdSalesforce,
  disabledCreateDocument,
  indexRelationship,
}: IViewRalationshipComponent) => {
  const displayMessage = useOnlyMessageToast();
  const displayMessageError = useMessageErrorToast();
  const { signOut } = React.useContext(LoginContext);
  const {
    faseOp,
    accessRelationship,
    setAccessAttachments,
    accessCreateOp,
    setAccessCreateOp,
    setIsUpdateAdress,
  } = React.useContext(OportunityContext);
  const [data, setData] = React.useState<any>([]);
  const [dataOp, setDataOp] = React.useState<any>([]);
  const [typeRelationship, setTypeRelationship] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [mainBuyer, setMainBuyer] = React.useState("");
  const [require, setRequire] = React.useState(false);
  const [type, setType] = React.useState("");

  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const [loadingGetRelationship, setLoadingGetRelationship] =
    React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [existCpf, setExistCpf] = React.useState(null);

  //Accordion
  const [nacionality, setNacionality] = React.useState("");
  const [ageRange, setAgeRange] = React.useState("");
  const [sex, setSex] = React.useState("");
  const [profession, setProfession] = React.useState("");
  const [civilState, setCivilState] = React.useState("");
  const [marriageRegime, setMarriageRegime] = React.useState("");
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);
  const [loadingUpdateAdress, setLoadingUpdateAdrees] = React.useState(false);

  const [dataFormik, setDataFormik] =
    React.useState<IValidationBuyersAccordionSchema>();
  const [originAccount, setOriginAccount] = React.useState("");
  const [selectProfession, setSelectProfession] = React.useState("");
  const [loadingProfession, setLoadingProfession] = React.useState(false);
  const [dataProfession, setDataProfession] = React.useState([]);
  const [idProfession, setIdProfession] = React.useState("");
  const [relationship, setRelationship] = React.useState<any>([]);
  const [observationsGa, setObservationsGa] = React.useState("");
  const [observationsSDR, setObservationsSDR] = React.useState("");
  const [cancelButtonAdress, setCancelButtonAdress] = React.useState(false);
  const [loadingGetOportunity, setloadingGetOportunity] = React.useState(false);

  const handleCancelButton = () => {
    setEdit(false);
    setIsEdit(false);
    setTypeRelationship(
      handleTypeRelationshipReverse(data?.tipoRelacionamento)
    );
    setCpf(data?.cpf);
    setMainBuyer(data?.compradorPrincipal ? "Sim" : "Não");
  };

  const handleTypeRelationship = (typeRelationship: string) => {
    if (typeRelationship === "Compositor de Renda") return 1;
    if (typeRelationship === "Cônjuge") return 2;
    if (typeRelationship === "Representante Legal") return 3;
    if (typeRelationship === "Fiador") return 4;
    if (typeRelationship === "Comprador Principal") return 5;
    return null;
  };
  const handleTypeRelationshipReverse = (typeRelationship: number) => {
    if (typeRelationship === 1) return "Compositor de Renda";
    if (typeRelationship === 2) return "Cônjuge";
    if (typeRelationship === 3) return "Representante Legal";
    if (typeRelationship === 4) return "Fiador";
    if (typeRelationship === 5) return "Comprador Principal";
    return "";
  };

  const handleGetOportunityById = async () => {
    setloadingGetOportunity(true);
    try {
      const response = await oportunityService.getOportunityById({
        OportunidadeId: accessCreateOp.oportunidadeId,
      });

      if (response.status === 401) {
        setloadingGetOportunity(false);
        return;
      }

      setObservationsSDR(response?.observacoesSDR);
      setObservationsGa(response?.observacoesGa);
      setRelationship(response?.relacionamentosComprador);
      setAccessCreateOp((prevState: any) => {
        return {
          ...prevState,
          nomeDaConta: response?.nomeDaConta,
          oportunityIdSalesforce: response?.opportunityIdSalesforce,
        };
      });
      setOriginAccount(response.origemConta);
      setNacionality(response.nacionalidade);
      setAgeRange(response.faixaEtaria);
      setSex(response.sexo);
      setProfession(
        response?.profissao === "" || response?.profissao === null
          ? ""
          : response?.profissao
      );
      setSelectProfession(
        response?.profissao === "" || response?.profissao === null
          ? ""
          : response?.profissao
      );
      setCivilState(response.estadoCivil);
      setMarriageRegime(response.regimeCasamento);
      setDataOp(response);
      setDataFormik(response);
      setloadingGetOportunity(false);
    } catch (error) {
      setloadingGetOportunity(false);
      console.log("ErrorHandleGetOportunityById", error);
    }
  };

  const handleGetRelationshipById = async () => {
    setLoadingGetRelationship(true);

    try {
      const response = await oportunityService.getRealtionshipById({
        IdRelacionamentoComprador: idRelacionamentoComprador,
      });

      if (response >= 400 && response !== 401) {
        setLoadingGetRelationship(false);
        setData([]);
        console.log("status 400", response);
        return;
      }
      if (response === 401) {
        setLoadingGetRelationship(false);
        setIsUnauthorized(true);
        setData([]);
        return;
      }

      setAccessAttachments({
        cpf: response?.cpf,
        codigoRelacionamentoComComprador:
          response?.codigoRelacionamentoComComprador,
      });
      setTypeRelationship(
        handleTypeRelationshipReverse(response?.tipoRelacionamento)
      );
      setType(handleTypeRelationshipReverse(response?.tipoRelacionamento));
      setExistCpf(response?.cpf);
      setCpf(response?.cpf);
      setMainBuyer(response?.compradorPrincipal ? "Sim" : "Não");
      setData(response);

      setLoadingGetRelationship(false);
    } catch (error) {
      setLoadingGetRelationship(false);
      console.log("errorHandleGetTaskCallById", error);
    }
  };

  const handleUpdateRelationship = async () => {
    setLoadingUpdate(true);
    setSuccessEditRelationship(false);

    try {
      const response = await oportunityService.updateRelationship({
        idRelacionamentoComprador: idRelacionamentoComprador,
        accountIdSalesforce,
        cpf,
        oportunidade: dataOp?.opportunityIdSalesforce,
        tipoRelacionamento: handleTypeRelationship(typeRelationship),
      });

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingUpdate(false);
        displayMessageError({
          message: "Erro ao atualizar relacionamento com o comprador!",
        });
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingUpdate(false);
        setIsUnauthorized(true);
        return;
      }

      setLoadingUpdate(false);
      setIsEdit(false);
      setEdit(false);
      displayMessage("Alterações salvas com sucesso!");
      setSuccessEditRelationship(true);
      handleGetRelationshipById();
    } catch (error) {
      setLoadingUpdate(false);
      console.log("errorHandleUpdateRelationship", error);
    }
  };

  const handleGetProffesion = async () => {
    setLoadingProfession(true);

    try {
      const response = await oportunityService.getProfission();

      if (response.status >= 400 && response?.status !== 401) {
        setLoadingProfession(false);
        setDataProfession([]);
        console.log("status 400", response);
        return;
      }
      if (response.status === 401) {
        setLoadingProfession(false);
        setIsUnauthorized(true);
        setDataProfession([]);
        return;
      }

      const dataArray: any = Object.values(response);

      setDataProfession(dataArray);
      setLoadingProfession(false);
    } catch (error) {
      setLoadingProfession(false);
      console.log("errorHandleGetProffesion", error);
    }
  };

  const handleCancelButtonAccordion = () => {
    setEdit(false);
    setCancelButtonAdress(true);
    formik.resetForm(initialValues);
    setCpf(data?.cpf);
    setOriginAccount(data?.origemConta);
    setNacionality(data?.nacionalidade);
    setAgeRange(data?.faixaEtaria);
    setSex(data?.sexo);
    setProfession(data?.profissao);
    setSelectProfession(data?.profissao);
    setCivilState(data?.estadoCivil);
    setMarriageRegime(data?.regimeCasamento);
  };

  const initialValues = {
    idOportunity: dataFormik?.oportunidadeId || "",
    accountOrigin: dataFormik?.origemConta || "",
    nameAccount: dataFormik?.nomeDaConta || "",
    mainTelephone: dataFormik?.telefonePrincipal || "",
    telephone: dataFormik?.telefoneComplementar || "",
    mainEmail: dataFormik?.emailPrincipal || "",
    email: dataFormik?.emailComplementar || "",
    dateBirth: dataFormik?.dataNascimento || "",
    cpf: dataFormik?.cpf || "",
    rg: dataFormik?.rg || "",
    shippingDate: dataFormik?.dataExpedicaoRG || "",
    issuingBody: dataFormik?.orgaoExpedidorRG || "",
    weddingDate: dataFormik?.dataCasamento || "",
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoadingUpdate(true);
      setIsUpdateAdress(false);
      setSuccessEditRelationship(false);
      try {
        const body = {
          opportunityIdSalesforce: accessCreateOp.oportunityIdSalesforce,
          OportunidadeId: accessCreateOp.oportunidadeId,
          accountIdSalesforce: accountIdSalesforce,
          origemDaConta: getValidStringOrNull(originAccount),
          telefoneComplementar: getValidStringOrNull(values?.telephone),
          emailComplementar: getValidStringOrNull(values?.email),
          emailPrincipal: getValidStringOrNull(values?.mainEmail),
          dataNascimento: getValidDateOrNull(values?.dateBirth),
          nacionalidade: getValidStringOrNull(nacionality),
          faixaEtaria: getValidStringOrNull(ageRange),
          sexo: getValidStringOrNull(sex),
          cpf: getValidStringOrNull(values?.cpf),
          rg: getValidStringOrNull(values?.rg),
          dataExpedicaoRG: getValidDateOrNull(values?.shippingDate),
          orgaoExpedidorRG: getValidStringOrNull(values?.issuingBody),
          profissao: getValidStringOrNull(profession),
          estadoCivil: getValidStringOrNull(civilState),
          dataCasamento: getValidDateOrNull(values?.weddingDate),
          regimeCasamento: getValidStringOrNull(marriageRegime),
        };

        const response = await oportunityService.updateOportunity(body);

        if (response.success === false) {
          setLoadingUpdate(false);
          displayMessageError({ message: response.err });
          return;
        }

        setEdit(false);
        setAccessCreateOp({ ...accessCreateOp });
        setLoadingUpdate(false);
        displayMessage("Alterações salvas com sucesso!");
        setSuccessEditRelationship(true);
        handleGetRelationshipById();
      } catch (error) {
        setLoadingUpdate(false);
        console.log("ErrorHandleGetOportunityById", error);
      }
    },
  });

  // React.useEffect(() => {
  //   const isCnpjComplete = documentMask(cpf)?.length === 18;
  //   const validDocument =
  //     (documentMask(cpf)?.length !== 0 && documentMask(cpf)?.length < 14) ||
  //     (documentMask(cpf)?.length > 14 && !isCnpjComplete);

  //   const isRequirement = !mainBuyer || !typeRelationship || validDocument;

  //   setRequire(isRequirement);
  // }, [cpf, mainBuyer, typeRelationship]);

  React.useEffect(() => {
    const marriedStatusNotFilled =
      civilState === "Casado(a)" &&
      (formik.getFieldProps("weddingDate").value.length < 10 ||
        marriageRegime === null ||
        marriageRegime === "");

    const isCnpjComplete =
      documentMask(formik.getFieldProps("cpf").value)?.length === 18;
    const validDocument =
      (documentMask(formik.getFieldProps("cpf").value)?.length !== 0 &&
        documentMask(formik.getFieldProps("cpf").value)?.length < 14) ||
      (documentMask(formik.getFieldProps("cpf").value)?.length > 14 &&
        !isCnpjComplete);
    if (type === "Comprador Principal") {
      const isRequirement =
        validDocument ||
        !formik.getFieldProps("mainEmail").value ||
        marriedStatusNotFilled ||
        !originAccount;
      //  ||requireAdress;

      setRequire(isRequirement);
    } else {
      const isRequirement = validDocument || !typeRelationship;
      //  ||requireAdress;

      setRequire(isRequirement);
    }
  }, [formik, civilState, marriageRegime, originAccount, type, typeRelationship]);

  React.useEffect(() => {
    handleGetOportunityById();
  }, [accessCreateOp.oportunidadeId, setAccessCreateOp]);

  React.useEffect(() => {
    if (isEdit) {
      setEdit(true);
    }
  }, [isEdit]);

  React.useEffect(() => {
    handleGetRelationshipById();
  }, [idRelacionamentoComprador]);

  return (
    <>
      <Alert
        onOpen={isUnauthorized}
        title="Erro ao carregar os dados!"
        titleButton={"Logar novamente"}
        description={
          "Sua sessão expirou, faça login novamente para ter acesso."
        }
        onClick={() => {
          signOut();
        }}
      />
      {loadingGetRelationship || loadingGetOportunity ? (
        <Styled.ContainerLoadingView>
          <Spinner />
        </Styled.ContainerLoadingView>
      ) : (
        <Styled.AreaSchedule>
          <Flex
            mt={"8px"}
            w={"fit-content"}
            alignItems={"center"}
            justifyContent={"center"}
            cursor={"pointer"}
            gap={"6px"}
            onClick={() => onBack()}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <BsArrowLeft fontSize={"18px"} />
            </Box>
            <Text fontSize={"14px"} fontWeight={"semibold"}>
              Voltar
            </Text>
          </Flex>

          <Flex gap={"8px"} mb={"14px"}>
            <Box
              display={"flex"}
              borderWidth={"1px"}
              borderColor={theme.disable}
              w={"45px"}
              h={"45px"}
              borderRadius={"full"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <BiUser fontSize={"18px"} />
            </Box>

            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              w={"fit-content"}
            >
              <Text fontWeight={"semibold"}>{title}</Text>
              <Text fontSize={"12px"}>{type}</Text>
            </Box>
          </Flex>

          <AccordionBuyersComponent
            formik={formik}
            getOp={handleGetOportunityById}
            accountIdSalesforce={accountIdSalesforce}
            cpf={cpf}
            setCpf={setCpf}
            type={type}
            indexRelationship={indexRelationship}
            handleSaveRelationship={handleUpdateRelationship}
            require={require}
            loadingUpdate={loadingUpdate}
            handleCancelButtonAccordion={handleCancelButtonAccordion}
            observationsGa={observationsGa}
            observationsSDR={observationsSDR}
            relationship={relationship}
            loadingUpdateAdress={loadingUpdateAdress}
            setLoadingUpdateAdress={setLoadingUpdateAdrees}
            edit={edit}
            setEdit={setEdit}
            originAccount={originAccount}
            setOriginAccount={setOriginAccount}
            nacionality={nacionality}
            setNacionality={setNacionality}
            ageRange={ageRange}
            setAgeRange={setAgeRange}
            sex={sex}
            setSex={setSex}
            civilState={civilState}
            setCivilState={setCivilState}
            marriageRegime={marriageRegime}
            setMarriageRegime={setMarriageRegime}
            profession={profession}
            setProfession={setProfession}
            selectProfession={selectProfession}
            setSelectProfession={setSelectProfession}
            getProfession={handleGetProffesion}
            loadingProfession={loadingProfession}
            dataProfession={dataProfession}
            setIdProfession={setIdProfession}
            cancelButtonAdress={cancelButtonAdress}
            setCancelButtonAdress={setCancelButtonAdress}
            idRelacionamentoComprador={idRelacionamentoComprador}
            codigoRelacionamentoComComprador={""}
            existCpf={existCpf}
            disabledCreateDocument={disabledCreateDocument}
            account={account}
            typeRelationship={typeRelationship}
            setTypeRelationship={setTypeRelationship}
          />
        </Styled.AreaSchedule>
      )}
    </>
  );
};

export default ViewRalationshipComponent;

const getValidStringOrNull: (v?: string) => string | null = (v) => {
  if (v === undefined) return null;
  if (v === "") return null;
  return v;
};

const getValidDateOrNull: (v?: string) => string | null = (v) => {
  if (v === undefined) return null;
  if (v === "") return null;
  if (v.length !== 10) return null;

  const fragments = v.split("/");
  return `${fragments[2]}-${fragments[1]}-${fragments[0]}T12:00:00.000Z`;
};

import api from "@services/api";
import apiNotification from "@services/apiNotification";
import axios from "axios";

interface IMarkViewed {
  tipoNotificacao: number;
  Id: string;
}

class NotifyService {
  async getNotify() {
    const access_token = sessionStorage.getItem("@tokenNotification");

    try {
      const response = await apiNotification.get("/notificacao/obter", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
      return false;
    }
  }

  async markViewed({ tipoNotificacao, Id }: IMarkViewed) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.patch(
        "/notificacao/marcar-visualizada",
        {},
        {
          params: {
            tipoNotificacao,
            Id,
          },
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }

  async markDisplayed(data: {
    idsNotificacaoNovaOp: string[];
    idsNotificacaoRetornoAC: string[];
    idsNotificacaoRetornoCotacao: string[];
  }) {
    const access_token = sessionStorage.getItem("@token");

    try {
      const response = await api.patch(
        "/notificacao/marcar-exibido",
        data, // Envia o body diretamente
        {
          headers: {
            access_token: `${access_token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorRes = error.response;
        return errorRes;
      }
    }
  }
}

const notifyServiceInstance = new NotifyService();
export default notifyServiceInstance;

import React from "react"
import { LoginContext } from "@context/loginContext"
import Menu from "../Menu"
import logo from "../../../assets/logo.svg"
import logoDirecional from "../../../assets/LogoDirecional.png"
import logoRiva from "../../../assets/Rivalogo.png"
import { useAuth } from "src/routes"
import { useNavigate } from "react-router-dom"
import { capitalize } from "@helpers/format"
import { linkSidebar } from "@helpers/linkSidebar"
import { Box, Divider, Flex, Icon, Image, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { UsuallyContext } from "@context/usuallyContex"
import { theme } from "src/styles/theme"
import { BiBell, BiUser } from "react-icons/bi"
import { SlOptionsVertical } from "react-icons/sl"
import DrawerNotification from "../DrawerNotification"
import DrawerUser from "../DrawerUser"
import notifyServiceInstance from "@services/Notify/notifyService"
import { OportunityContext } from "@context/oportunityContex"

const MenuSideBar = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    openSidebar,
    signOut,
    pageSidebar,
    setPageSidebar,
    notifications,
    setNotifications,
    isMarkedNotifications,
    setIsMarkedNotifications,
  } = React.useContext(LoginContext)
  const { setHashSalesSidebar, setHashDetailOportunity, setHashOportunity } = React.useContext(UsuallyContext)
  const { setAccessCreateOp } = React.useContext(OportunityContext)
  const isAuthenticated = useAuth()
  const navigate = useNavigate()
  const user: any = localStorage.getItem("@user")
  const email: any = localStorage.getItem("@email")
  const [openUser, setOpenUser] = React.useState(false)

  const handleOptionClick = (label: string) => {
    setPageSidebar(label)
    localStorage.setItem("@option", label)
    toast.closeAll()
  }

  const handleClickImg = (e: any) => {
    e.stopPropagation()
    setPageSidebar("Minhas oportunidades")
    navigate("/oportunidades")
    // navigate("/");
    // localStorage.setItem("@option", "Principal");
    localStorage.setItem("@option", "Minhas oportunidades")
    toast.closeAll()
  }

  const handleSignOut = (e: any) => {
    e.stopPropagation()
    signOut()
    toast.closeAll()
  }

  function handleNavigateOportunity(name: string) {
    if (name?.toLowerCase() === "criar oportunidade") {
      setHashSalesSidebar("Oportunity")
    } else if (name?.toLowerCase() === "minhas oportunidades") {
      setHashSalesSidebar("MyOportunityComponent")
      setHashDetailOportunity("DetailOportunity")
    } else {
      setHashSalesSidebar("")
    }
  }

  function handleNavigationSales() {
    setHashOportunity("Oportunity")
    setHashSalesSidebar("Oportunity")
    setHashDetailOportunity("DetailOportunity")
  }
  function handleNavigationMyOportunity() {
    setHashOportunity("MyOportunityComponent")
    setHashSalesSidebar("MyOportunityComponent")
    setHashDetailOportunity("DetailOportunity")
    setAccessCreateOp({
      nomeDaConta: "",
      oportunidadeId: "",
      oportunityIdSalesforce: "",
    })
  }

  const handleNavigateMenu = (label: string, to: string) => {
    if (label === "Notificações") {
      onOpen() // Abre o drawer de notificações
      handleMarkDisplayed();
      handleOptionClick(label);
    } else if (label === "Criar oportunidade") {
      handleNavigationSales()
      handleOptionClick(label)
      navigate(to)
      handleNavigateOportunity(label)
    } else if (label === "Minhas oportunidades") {
      handleNavigationMyOportunity()
      handleOptionClick(label)
      navigate(to)
      handleNavigateOportunity(label)
    } else {
      handleOptionClick(label)
      navigate(to)
      handleNavigateOportunity(label)
    }
  }

  const truncateName = (name: string, maxLength: number) => {
    if (name?.length > maxLength) {
      return name?.slice(0, maxLength) + "..."
    } else return name
  }

  const handleMarkDisplayed = async () => {
    const idsNotificacaoNovaOp = notifications
      .filter((notification) => notification.tipoNotificacao === "NovaOP")
      .map((notification) => notification.id)

    const idsNotificacaoRetornoAC = notifications
      .filter((notification) => notification.tipoNotificacao === "RetornoAC")
      .map((notification) => notification.id)

    const idsNotificacaoRetornoCotacao = notifications
      .filter((notification) => notification.tipoNotificacao === "RetornoCotacao")
      .map((notification) => notification.id)

    const data = {
      idsNotificacaoNovaOp,
      idsNotificacaoRetornoAC,
      idsNotificacaoRetornoCotacao,
    }

    try {
      const response = await notifyServiceInstance.markDisplayed(data)

      if (response?.status >= 400 && response?.status !== 401) {
        console.log("Erro ao marcar notificações como exibidas:", response)
        return
      }

      if (response?.status === 401) {
        console.log("Usuário não autorizado:", response)
        setIsMarkedNotifications(false)
        return
      }
    } catch (error) {
      console.error("Erro ao marcar notificações como exibidas:", error)
    }
  }

  React.useLayoutEffect(() => {
    const storedOption: string | null = localStorage.getItem("@option")

    if (storedOption) {
      setPageSidebar(storedOption)
      handleNavigateOportunity(storedOption)
    }
  }, [setHashDetailOportunity, setHashSalesSidebar, setPageSidebar])

  return (
    <>
      <DrawerNotification
        isOpen={isOpen}
        onClose={onClose}
        notifications={notifications}
        setNotifications={setNotifications}
        setIsMarked={setIsMarkedNotifications}
      />

      <DrawerUser isOpen={openUser} onClose={() => setOpenUser(false)} />

      <Flex
        display={isAuthenticated ? "flex" : "none"}
        pos={"fixed"}
        top={0}
        left={0}
        flexDir={"column"}
        w={{ base: 0, lg: "230px" }}
        h={"100%"}
        background={theme.primary700}
        zIndex={999}
        transition={"0.2s"}
      >
        <Box display={{ base: "flex", lg: "none" }}>
          <Menu
            selectedOption={pageSidebar}
            setSelectedOption={setPageSidebar}
            isOpen={openSidebar}
            isSelected={pageSidebar === "Profile"}
            onClickProfile={() => {
              navigate("/myProfile")
              setPageSidebar("Profile")
              localStorage.setItem("@option", "Profile")
            }}
          />
        </Box>

        <Flex display={{ base: "none", lg: "flex" }} flexDir={"column"} w={"100%"} h={"100%"}>
          <Flex p={"2px"} justifyContent="space-around" alignItems="center" mt={5}>
            {/* Logo */}
            <Box bg="gray.600" display={"flex"} w={"fit-content"} p={1} borderRadius={"8px"}>
              <Image src={logo} alt="Logo" onClick={handleClickImg} cursor="pointer" w={"140px"} />
            </Box>

            {/* Ícone de Sino com Bolinha Vermelha e Animação */}
            <Box
              position="relative"
              w={"fit-content"}
              h={"fit-content"}
              onClick={() => {
                onOpen()
                handleMarkDisplayed();
              }}
            >
              <Icon
                cursor={"pointer"}
                fontSize="22px"
                color="white"
                as={BiBell}
                _hover={{ animation: "shake 0.5s" }} // Ativa a animação de balanço
              />

              {/* Bolinha Vermelha */}
              {isMarkedNotifications && (
                <Box
                  position="absolute"
                  top="-3px"
                  right="-3px"
                  bg={theme.error}
                  w="8px"
                  h="8px"
                  borderRadius="full"
                ></Box>
              )}
            </Box>

            {/* Keyframes da Animação */}
            <style>
              {`
          @keyframes shake {
            0% { transform: rotate(0deg); }
            25% { transform: rotate(-10deg); }
            50% { transform: rotate(10deg); }
            75% { transform: rotate(-10deg); }
            100% { transform: rotate(0deg); }
          }
        `}
            </style>
          </Flex>

          {/* Meu perfil */}
          <Flex mt={8} p={1} justifyContent={"space-around"} cursor={"pointer"} onClick={() => setOpenUser(true)}>
            <Box
              display={"flex"}
              bg={theme.disable100}
              w={"35px"}
              h={"35px"}
              borderRadius={"full"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <BiUser />
            </Box>

            <Box display={"flex"} flexDir={"column"} justifyContent={"center"} alignItems={"center"} w={"fit-content"}>
              <Text fontSize={"11px"} color={"white"}>
                {truncateName(capitalize(user), 28)}
              </Text>
              <Text fontSize={"10px"} color={"white"}>
                {truncateName(email, 28)}
              </Text>
            </Box>

            <Box display={"flex"} flexDir={"column"} justifyContent={"center"} alignItems={"center"} w={"fit-content"}>
              <Icon fontSize="18px" color={"white"}>
                <SlOptionsVertical />
              </Icon>
            </Box>
          </Flex>

          <Flex p={2} mt={5} mb={8}>
            <Divider borderColor={theme.disable} />
          </Flex>

          {/* Options */}
          <Box display={"flex"} flexDir={"column"} gap={3}>
            {linkSidebar.map(({ icon, label, to }) => (
              <Flex
                key={label}
                flexDir={"column"}
                w={"97%"}
                h={"fit-content"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  w={"95%"}
                  h={"45px"}
                  pl={"8px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={"8px"}
                  gap={2}
                  cursor={"pointer"}
                  bg={pageSidebar === label ? theme.backgroundPrimary : "transparent"}
                  _hover={{
                    bg: theme.backgroundGray,
                  }}
                  onClick={() => {
                    handleNavigateMenu(label, to)
                  }}
                >
                  <Icon
                    fontSize="18px"
                    color={"white"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {icon}
                  </Icon>

                  <Text color={"white"} fontSize={"15px"} fontWeight={pageSidebar === label ? "bold" : "normal"}>
                    {label}
                  </Text>
                </Box>
              </Flex>
            ))}
          </Box>

          {/* Logos Footer */}
          <Flex justifyContent={"space-between"} alignItems={"flex-end"} h={"100%"} mb={15}>
            <Flex w={"95%"} h={"13%"} alignItems={"center"} justifyContent={"center"} pl={"6px"}>
              <Box display={"flex"} w={"100%"} justifyContent={"center"} alignItems={"center"}>
                <Image src={logoDirecional} alt="logoDirecional" w={"fit-content"} h={"12px"} />
              </Box>
            </Flex>

            <Flex w={"95%"} h={"13%"} alignItems={"center"} justifyContent={"center"} pl={"6px"}>
              <Box display={"flex"} w={"100%"} justifyContent={"center"} alignItems={"center"}>
                <Image src={logoRiva} alt="logoRiva" w={"fit-content"} h={"20px"} />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default MenuSideBar

import { useToast, Box, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { theme } from "src/styles/theme";

interface MessageParams {
  message: string;
  whiteSpace?: any;
}

export const useMessageErrorToast = () => {
  const toast = useToast();

  const displayMessageError = useCallback(
    ({ message, whiteSpace = undefined }: MessageParams) => {
      toast.closeAll();
      toast({
        status: undefined,
        isClosable: true,
        render: () => (
          <Box display={"flex"} bg={theme.error} p={4} borderRadius={"8px"}>
            <Text color={"white"} fontWeight={"bold"} whiteSpace={whiteSpace}>
              {message}
            </Text>
          </Box>
        ),
        containerStyle: {
          w: "fit-content",
          boxShadow: "dark-lg",
          borderRadius: "8px",
        },
      });
    },
    [toast]
  );

  return displayMessageError;
};

import React from "react"
import { useNavigate } from "react-router-dom"
import { ILoginContext, IProps, SignInProps } from "../@types/context/loginContext.structure"
import loginService from "@services/Login/loginService"
import { datadogRum } from "@datadog/browser-rum"
import notifyServiceInstance from "@services/Notify/notifyService"

export const LoginContext = React.createContext({} as ILoginContext)

const LoginContextProvider: React.FC<IProps> = ({ children }) => {
  const navigate = useNavigate()
  const token = sessionStorage.getItem("@token")
  const [user, setUser] = React.useState<boolean>(false)
  const isAuthenticated = user
  const [profile, setProfile] = React.useState([])
  const [regional, setRegional] = React.useState(localStorage.getItem("@regional"))
  const [company, setCompany] = React.useState(localStorage.getItem("@empresa"))
  const [errorLogin, setErrorLogin] = React.useState<boolean>(false)
  const [loadingLogin, setLoadingLogin] = React.useState<boolean>(false)
  const [openSidebar, setOpenSidebar] = React.useState<boolean>(true)
  const [errorForgotPassword, setErrorForgotPassword] = React.useState<boolean>(false)
  const [hashLogin, setHashLogin] = React.useState<string>("CurrentLogin")
  const [page, setPage] = React.useState<string>("")
  const [email, setEmail] = React.useState<string>("")
  const [pageHelper, setPageHelper] = React.useState<string>("")
  const [pageSidebar, setPageSidebar] = React.useState<string>("")

  const [isRemakeLogin, setIsRemakeLogin] = React.useState(false)
  const [notifications, setNotifications] = React.useState<any[]>([])
  const [isMarkedNotifications, setIsMarkedNotifications] = React.useState(false)

  async function signIn({ email, password }: SignInProps) {
    setLoadingLogin(true)
    try {
      const response = await loginService.postLogin({
        email,
        senha: password,
      })

      if (response?.status >= 400) {
        console.log("errorLogin", response)
        setErrorLogin(true)
        setLoadingLogin(false)
        return
      }

      if (response.titulo === "Dado divergente") {
        console.log("Dado divergente", response)
        setErrorLogin(true)
        setLoadingLogin(false)
        return
      }

      const { access_token, idToken } = response

      if (response.deveRefazerPrimeiroAcesso) {
        setIsRemakeLogin(true)
        setLoadingLogin(false)
        return
      }

      sessionStorage.setItem("@token", access_token)
      sessionStorage.setItem("@tokenNotification", idToken)
      localStorage.setItem("@tipoDeUsuario", response?.tipoDeUsuario)
      localStorage.setItem("@idCorretor", response?.idCorretorSalesforce)
      localStorage.setItem("@email", email)
      localStorage.setItem("@user", response?.nomeCorretor)
      localStorage.setItem("@regional", response?.regional)
      localStorage.setItem("@regiao", response?.regiao)
      localStorage.setItem("@empresa", response?.empresa)
      localStorage.setItem("@cpf", response?.cpf)
      localStorage.setItem("@dtNasc", response?.dataNascimento)
      localStorage.setItem("@option", "Criar oportunidade")
      // setPageSidebar("Principal");
      setPageSidebar("Criar oportunidade")

      datadogRum.setUser({
        id: response?.idCorretorSalesforce,
        name: response?.nomeCorretor,
        email: email,
        regional: response?.regional,
        empresa: response?.empresa,
        regiao: response?.regiao,
        cpf: response?.cpf,
      })

      setRegional(response?.regional)
      setCompany(response?.empresa)
      setEmail(email)
      setUser(true)
      // navigate("/home");
      navigate("/oportunidades")
      setLoadingLogin(false)
      setErrorLogin(false)
      setPage("")
      await fetchNotifications();
    } catch (error) {
      setLoadingLogin(false)
      console.log("ERRO AO FAZER LOGIN ", error)
    }
  }

  function refreshPage() {
    setTimeout(() => {
      window.location.reload()
      sessionStorage.removeItem("@token")
      localStorage.setItem("@option", "Criar oportunidade")
      datadogRum.clearUser()
    }, 500)
  }

  function signOut() {
    try {
      refreshPage()
    } catch {
      console.log("erro ao deslogar")
    }
  }

  const fetchNotifications = async () => {
    try {
      const response = await notifyServiceInstance.getNotify()

      if (response.status >= 400 && response?.status !== 401) {
        setNotifications([])
        console.log("status 400", response)
        return
      }
      if (response.status === 401) {
        setNotifications([])
        return
      }

      // Verificar se há alguma notificação com exibido === null
      const hasUnreadNotifications = Array.isArray(response)
        ? response.some((notification) => notification.exibido === null)
        : false

      setIsMarkedNotifications(hasUnreadNotifications)

      setNotifications(Array.isArray(response) ? response : [])
    } catch (error) {
      console.log("Erro ao buscar notificações:", error)
      setNotifications([])
    }
  }

  React.useEffect(() => {
    if (!token) return

    const fetchIfActive = () => {
      if (document.hidden) return
      fetchNotifications();
    }

    fetchIfActive()

    // Configura o intervalo em 1h
    const interval = setInterval(fetchIfActive, 3600000)

    // Adiciona evento para atualizar quando a aba fica visível
    const handleVisibilityChange = () => {
      if (!document.hidden) return fetchNotifications();
    }
    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      clearInterval(interval)
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [token])

  return (
    <LoginContext.Provider
      value={{
        fetchNotifications,
        notifications,
        setNotifications,
        isMarkedNotifications,
        setIsMarkedNotifications,
        regional,
        setRegional,
        setCompany,
        company,
        hashLogin,
        setHashLogin,
        user,
        setUser,
        email,
        openSidebar,
        setOpenSidebar,
        isAuthenticated,
        page,
        setPage,
        pageHelper,
        setPageHelper,
        profile,
        setProfile,
        signIn,
        signOut,
        errorLogin,
        setErrorLogin,
        loadingLogin,
        errorForgotPassword,
        setErrorForgotPassword,
        pageSidebar,
        setPageSidebar,
        isRemakeLogin,
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

export default LoginContextProvider

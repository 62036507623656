import axios from "axios";

function handleUrl() {
  if (window.location.href.includes("localhost"))
    return "https://backend-dev-eks.boravender.app.br/regras";

  if (window.location.href.includes("dev"))
    return "https://backend-dev-eks.boravender.app.br/regras";

  if (window.location.href.includes("qa"))
    return "https://backend-qa-eks.boravender.app.br/regras";

  if (window.location.href.includes("stage"))
    return "https://backend-stage-eks.boravender.app.br/regras";

  return "https://backend-prod-eks.boravender.app.br/regras";
}

const apiNotification = axios.create({
  baseURL: handleUrl(),
  headers: {
    client_id: "bfa95331-1eee-46bb-99ec-b79760a58a8e",
  },
});

export default apiNotification;
